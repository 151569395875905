<template>
  <div class="vg_wrapper">
    <el-card>
      <publicTips></publicTips>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="getMstksNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="物料编号：">
                <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料名称：">
                <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料类型：">
                <el-select size="small" v-model="searchForm.mtrl_type" placeholder="请选择物料类型" clearable>
                  <el-option v-for="item in mtrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="潘通色号：">
                <el-input size="small" v-model="searchForm.mtrl_color" clearable placeholder="请填写潘通色号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="库存状态：">
                <el-select size="small" v-model="searchForm.mstk_status" placeholder="请选择库存状态" clearable>
                  <el-option v-for="item in state" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料门幅：">
                <el-input
                  size="small"
                  v-model.trim="searchForm.mtrl_width"
                  @input="
                    searchForm.mtrl_width =
                      searchForm.mtrl_width
                        .replace(/^0+(\d)/, '$1')
                        .replace(/^\./, '0.')
                        .match(/^\d*(\.?\d{0,4})/g)[0] || ''
                  "
                  @change="searchForm.mtrl_width = helper.reservedDigits4(searchForm.mtrl_width)"
                  clearable
                  placeholder="请填写物料门幅"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="颜色大类：">
                <el-select filterable size="small" v-model="searchForm.colr_class" placeholder="请选择颜色大类" clearable multiple>
                  <el-option v-for="item in optnColorList" :key="item.param1" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料规格：">
                <el-input size="small" v-model.trim="searchForm.mtrl_spec" clearable placeholder="请填写物料规格"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="所属部门：">
                <selectSectionType ref="selectSectionType" @selectStatusRow="getStatusVal2" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="物料毛高：">
                <el-input
                  size="small"
                  v-model.trim="searchForm.mtrl_thick"
                  clearable
                  @input="searchForm.mtrl_thick = helper.keepEngNum4(searchForm.mtrl_thick)"
                  placeholder="请填写物料毛高"
                >
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :md="16">
              <el-form-item label="修改时间范围：">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMstksNow()" class="vg_ml_16">查询 </el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item>
                <el-button type="danger" size="small" class="vd_export" @click="remove()"><i class="el-icon-delete"></i> 删除</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16"></div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            @sort-change="sortChange"
            :data="tableData"
            border
            v-loading="loadFlag"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" align="center"></el-table-column>
            <el-table-column label="物料编号" prop="mtrl_no" />
            <el-table-column label="物料图片" align="center">
              <template slot-scope="scope">
                <el-popover v-if="scope.row.imge_url" placement="right" width="400" trigger="hover">
                  <div>
                    <el-image style="width: 100%" class="vd_popimg" :src="formatPic(scope, 'l')" fit="fill"> </el-image>
                  </div>
                  <el-image slot="reference" class="vd_elimg" :src="formatPic(scope, 's')" fit="fill"> </el-image>
                </el-popover>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料名称" prop="mtrl_name" show-overflow-tooltip />
            <el-table-column label="物料规格" prop="mtrl_spec">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_spec">{{ scope.row.mtrl_spec }}</span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料类型" prop="mtrl_type" :formatter="formatLeavType" />
            <el-table-column label="潘通色号" prop="mtrl_color">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_color">
                  <div class="vd_dis">
                    <div :class="{ vd_div: scope.row.colr_html }" :style="{ 'background-color': scope.row.colr_html }"></div>
                    <span>{{ scope.row.mtrl_color }}</span>
                  </div>
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column prop="colr_class" label="颜色大类" align="center">
              <template slot-scope="scope">
                <span v-if="scope.row.colr_class">
                  {{ scope.row.colr_class }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="库存状态" :show-overflow-tooltip="true" align="center">
              <template slot-scope="scope">
                <el-tag :type="getStatusName(scope.row.mstk_status).type" size="mini">{{
                  getStatusName(scope.row.mstk_status).name
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="库存数量" prop="mstk_num">
              <template slot-scope="scope">
                <span v-if="scope.row.mstk_num">
                  {{ scope.row.mstk_num | formatMstkNum }}
                </span>
                <span v-else class="vg_9f9a9a">0.0000</span>
              </template>
            </el-table-column>
            <el-table-column label="物料单位" prop="mtrl_unit" width="80">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_unit">
                  {{ scope.row.mtrl_unit }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="安全库存" prop="mtrl_stock_limit">
              <template slot-scope="scope">
                {{ scope.row.mtrl_stock_limit | formatMtrlStockLimit }}
              </template>
            </el-table-column>
            <el-table-column label="物料毛高" sortable prop="mtrl_thick">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_thick">
                  {{ scope.row.mtrl_thick | formaUnitH }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料门幅" prop="mtrl_width">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_width">
                  {{ scope.row.mtrl_width | formaUnitM }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="所属部门" prop="belo_dept_name"></el-table-column>
            <el-table-column label="修改时间" prop="update_time" :formatter="formatDate" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column label="二维码信息" sortable width="120px" align="center">
              <template slot-scope="scope">
                <div class="QrDiv">
                  <el-button size="mini" type="success" class="QrBtn" @click="getQrCodeData(scope.row)">查看二维码 </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
        </el-col>
      </el-row>

      <el-dialog :title="title" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
        <el-row v-if="qrCodeList.length > 0">
          <el-col class="handleRow" :md="5" v-for="item in qrCodeList" :key="item.qrcd_id">
            <el-row>
              <el-col :md="8">
                <div class="lableDiv">
                  <span>二维码：</span>
                </div>
              </el-col>
              <el-col :md="7">
                <div>
                  <el-image class="vd_elimg" v-if="item.qrcd_url" :src="formatPicForQrCode(item, 'l')" fit="fill"> </el-image>
                  <span v-else class="vg_9f9a9a">暂无</span>
                </div>
              </el-col>
            </el-row>

            <el-row>
              <span>物料名称：</span>
              <span v-if="item.mtrl_name">{{ item.mtrl_name }}</span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </el-row>

            <el-row>
              <span>物料编号：</span>
              <span v-if="item.mtrl_no">{{ item.mtrl_no }}</span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </el-row>

            <el-row>
              <span>库位：</span>
              <span v-if="item.qrcd_bin_no">{{ item.qrcd_bin_no }}</span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </el-row>

            <el-row>
              <span>数量：</span>
              <span v-if="item.qrcd_num">{{ item.qrcd_num }}</span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </el-row>
            <el-row class="delRow">
              <el-button size="mini" type="danger" @click="delQrCode(item)">删除</el-button>
            </el-row>
          </el-col>
        </el-row>
        <el-row v-else class="noDataRow">暂无数据</el-row>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mstkAPI } from '@api/modules/mstk';
import { qrcdAPI } from '@api/modules/qrcd';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';
import publicTips from '@/views/component/publicTips';
import helper from '@assets/js/helper.js';

export default {
  name: 'MstkList',
  components: {
    pubPagination,
    publicTips,
    selectSectionType
  },
  data() {
    return {
      title: '查看二维码信息',
      tableData: [],
      dialogVisible: false,
      searchForm: {
        mtrl_no: null,
        mtrl_thick: null,
        mtrl_sort: null,
        mtrl_type: null,
        flag: 0,
        mtrl_color: null,
        mtrl_width: null, //物料门幅
        mtrl_spec: null, //物料规格
        mtrl_field: null,
        colr_class: [],
        timeValue: {
          startTime: null,
          endTime: null
        },
        mstk_status: null,
        belo_dept_id: null //所属部门
      },
      qrCodeList: [],
      qr_mstk_id: null,
      totalPage: 0,
      btn: {},
      loadFlag: true,
      currentPage: 1,
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],

      // {id:3,label:'加工面料'},
      state: [
        { id: 0, label: '库存不足' },
        { id: 1, label: '库存充足' }
      ],
      multipleSelection: [],
      optnColorList: [],
      isDyj: true,
      beloDeptId: null
    };
  },
  created() {
    this.getDepartment();
    if (this.$route.query.mtrl_no) {
      this.searchForm.mtrl_no = this.$route.query.mtrl_no;
    }
    this.initData();
  },
  mounted() {
    this.$EventBus.$on('uploading', res => {
      this.getMstksList();
    });
  },
  filters: {
    formatMstkNum(row) {
      return helper.haveFour(row);
    },
    formatMtrlStockLimit(row) {
      return helper.haveFour(row);
    },
    formatQrcdMtrlThick(row) {
      return helper.reservedDigits(row);
    },
    formaUnitM(row) {
      return helper.haveFour(row) + '米';
    },
    formaUnitH(row) {
      return helper.reservedDigits(row) + '毫米';
    }
  },
  methods: {
    initData() {
      this.getOptnColor();
      this.getMstksList();
      // this.getDepartment()
    },
    //获取打样部门
    getDepartment() {
      let userInfo = this.$cookies.get('userInfo');
      console.log('userInfo', userInfo);
      if (userInfo.dept_id === 2 || userInfo.dept_id === 17) {
        this.searchForm.belo_dept_id = userInfo.dept_id + '';
        this.beloDeptId = userInfo.dept_id + '';
        this.isDyj = true;
      } else {
        this.searchForm.belo_dept_id = null;
        this.isDyj = false;
      }
    },
    // 获取二维码信息
    getMstksList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      let colr_class = null;
      if (this.searchForm.colr_class.length > 0) {
        colr_class = this.searchForm.colr_class.join(',');
      }
      get(mstkAPI.getMstks, {
        mtrl_no: this.searchForm.mtrl_no,
        mtrl_thick: this.searchForm.mtrl_thick,
        mtrl_sort: this.searchForm.mtrl_sort,
        mtrl_field: this.searchForm.mtrl_field,
        mtrl_name: this.searchForm.mtrl_name,
        mtrl_type: this.searchForm.mtrl_type,
        mtrl_color: this.searchForm.mtrl_color,
        colr_class,
        flag: this.searchForm.flag,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        page_no: this.currentPage,
        mstk_status: this.searchForm.mstk_status,
        mtrl_width: this.searchForm.mtrl_width,
        mtrl_spec: this.searchForm.mtrl_spec,
        belo_dept_id: this.searchForm.belo_dept_id
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    //获取颜色大类option
    getOptnColor() {
      get(optnAPI.getAllContent, { perm_id: 10010 })
        .then(res => {
          if (res.data.code === 0) {
            this.optnColorList = res.data.data;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 转换二维码图片
    formatPicForQrCode(scope, type) {
      return this.helper.picUrl(scope.qrcd_url, type, scope.create_time);
    },
    // 删除
    remove() {
      if (this.multipleSelection.length == 0) {
        return this.$message.warning('至少选择一条数据');
      }
      this.$confirm('此操作将永久删除该物料, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          let mulArr = [];
          this.multipleSelection.forEach(item => {
            mulArr.push(item.mstk_id);
          });
          post(mstkAPI.destroy_mstk_by_ids, { mstk_id_list: mulArr })
            .then(res => {
              if (res.data.code == 0) {
                this.$message.success(res.data.msg);
                if (mulArr.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.getMstksList();
                return;
              }
              this.$message.warning(res.data.msg);
            })
            .catch(res => {
              this.$message.error(res.data.msg);
            });
        })
        .catch(res => {});
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        mtrl_no: null,
        mtrl_thick: null,
        mtrl_name: null,
        mtrl_type: null,
        mtrl_color: null,
        colr_class: [],
        timeValue: {
          startTime: null,
          endTime: null
        }
      };
      if (this.isDyj) {
        this.searchForm.belo_dept_id = this.beloDeptId;
      } else {
        this.searchForm.belo_dept_id = null;
        this.$refs.selectSectionType.value2 = '全部';
      }
      this.loadFlag = true;
      this.currentPage = 1;
      this.getMstksList();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 图片类型
    formatPic(scope, type) {
      return this.helper.picUrl(scope.row.imge_url, type, scope.row.create_time);
    },
    // 转换合同状态
    getStatusName(status) {
      if (status === 1) {
        return { name: '库存充足', type: 'success' };
      } else if (status === 0) {
        return { name: '库存不足', type: 'warning' };
      }
    },
    // 查询方法
    getMstksNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getMstksList();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMstksList();
    },
    // 物料类型
    formatLeavType(row) {
      if (row.mtrl_type === 0) {
        return '原面料';
      } else if (row.mtrl_type === 1) {
        return '辅料';
      } else if (row.mtrl_type === 2) {
        return '包材';
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.update_time);
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.belo_dept_id = val;
    }, //升序 降序
    sortChange(val) {
      console.log('val', val);
      if (val.prop) {
        if (val.order == 'ascending') {
          this.searchForm.mtrl_sort = 'asc';
          this.searchForm.mtrl_field = val.prop;
        } else if (val.order == 'descending') {
          this.searchForm.mtrl_sort = 'desc';
          this.searchForm.mtrl_field = val.prop;
        } else {
          this.searchForm.mtrl_sort = '';
          this.searchForm.mtrl_field = '';
        }
      } else {
        if (val.order == 'ascending') {
          this.searchForm.flag = 0;
        } else if (val.order == 'descending') {
          this.searchForm.flag = 1;
        } else {
          this.searchForm.flag = 0;
        }
      }
      this.loadFlag = true;
      this.searchForm.page_no = 1;
      this.getMstksList();

      // 升序 asc
      // 降序 desc
    },
    //二维码信息按钮
    getQrCodeData(row) {
      this.qr_mstk_id = row.mstk_id;
      this.getQrCodeDataMain(this.qr_mstk_id);
    },
    // 获取二维码信息接口
    getQrCodeDataMain(id) {
      get(mstkAPI.getMstkQrcds, { mstk_id: id })
        .then(res => {
          if (res.data.code === 0) {
            this.qrCodeList = res.data.data.list;
            this.dialogVisible = true;
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 删除二维码确认
    delQrCode(item) {
      this.$confirm('确定删除该二维码？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          ids.push(item.qrcd_id);
          post(qrcdAPI.deleteQrcdByIds, { qrcd_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                this.getQrCodeDataMain(this.qr_mstk_id);
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.getQrCodeDataMain(this.qr_mstk_id);
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.getQrCodeData(this.qr_mstk_id);
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
  margin-top: 5px;
}

.vd_dis {
  display: flex;
}

.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}

.vd_popimg {
  width: 400px;
  height: 400px;
}

// 查看二维码信息按钮
.QrBtn {
  padding: 8px;
}

.QrDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lableDiv {
  margin-top: 50%;
}
.delRow {
  margin-top: 10px;
  text-align: center;
}
.handleRow {
  border: 1px solid rgba($color: grey, $alpha: 0.3);
  padding: 10px;
  margin-right: 20px;
}
.noDataRow {
  padding-left: 45%;
  color: grey;
}
</style>
